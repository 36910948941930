@let userProfile = profileFacade.userProfileSignal() ;
@let value = formModel.formGroup.controls[ProfileManagerFormControl.PROFILE].value ;

<p-sidebar styleClass="rad-background-color-content h-auto"
           position="right"
           [(visible)]="displayProfileManager">
    <ng-template pTemplate="content">
        <app-form [formModel]="formModel"
                  [saveButtonLabel]="(userProfile ? userProfile?.email === value?.[LoginFormControl.USERNAME] ? 'logout' : value ? 'switch' : 'logout' : 'login') | translate"
                  [displayResetButton]="false"
                  [displayCancelButton]="false"
                  [displaySaveButton]="!!(value || userProfile)"
                  [inputsOptions]="inputsOptions"
                  (save)="login($event)"></app-form>
    </ng-template>
</p-sidebar>

<p-button styleClass="p-button-icon-only"
          [ngStyle]="{position: 'fixed', bottom: '5%', right: 0}"
          [size]="Size.SMALL"
          [rounded]="true"
          (onClick)="displayProfileManager = !displayProfileManager">
    <app-google-icon [icon]="GoogleIcon.PERSON"></app-google-icon>
</p-button>
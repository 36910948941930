import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {catchError, throwError} from 'rxjs';
import {ErrorType} from 'src/app/enums/backend/error-type.enum';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {ERROR_TOAST_IGNORED_URLS} from 'src/app/helpers/core/site-config.helper';
import {ToastService} from 'src/app/services/core/toast.service';
import {environment} from 'src/environments/environment';

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
    const profileFacade = inject(ProfileFacade);
    const toastService = inject(ToastService);

    return next(req).pipe(
        catchError((err) => {
            const status = err?.status?.toString();
            if (['502', '401'].includes(status)) profileFacade.logout();
            if (!shouldRequestBeIgnored(req.urlWithParams)) toastService.displayError(err?.error?.error === ErrorType.INVALID_GRANT ? 'invalidLoginOrPassword' : undefined);
            return throwError(() => err);
        })
    );
};

const shouldRequestBeIgnored = (reqUrl: string) => {
    return ERROR_TOAST_IGNORED_URLS.some((entry) => {
        const reqUrlNoQueryParams = reqUrl.split('?')[0].replace(environment.ED24_SERVER, '');
        const reqUrlParts = reqUrlNoQueryParams.split('/');
        const urlParts = entry.split('/');
        if (urlParts.length !== reqUrlParts.length) return false;

        const results = urlParts.map((innerEntry, index) => {
            if (innerEntry === ':param') {
                return true;
            }

            return innerEntry === reqUrlParts[index];
        });

        return results.every(innerEntry => innerEntry);
    });
};
import {Component, EventEmitter, Input, model, ModelSignal, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmDialogComponent} from 'src/app/components/utilities/confirm-dialog/confirm-dialog.component';
import {Color} from 'src/app/enums/core/color.enum';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';

const RAD_STANDALONE_COMPONENTS = [
    ConfirmDialogComponent
];

@Component({
    selector: 'app-confirm-delete-dialog',
    standalone: true,
    imports: [
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS
    ],
    templateUrl: './confirm-delete-dialog.component.html'
})
export class ConfirmDeleteDialogComponent {
    @Input() public width: string = '26.25rem';
    @Input() public maxWidth?: string;
    @Input() public fullWidth: boolean = false;
    @Input() public fullHeight: boolean = false;
    @Input() public header: string = '';
    @Input() public centerHeader: boolean = false;
    @Input() public headerIcon?: GoogleIcon;
    @Input() public headerIconColor?: Color;
    @Input() public headerIconNewRow: boolean = false;
    @Input() public blur: boolean = false;
    @Input() public closable = true;
    @Input() public confirmButtonLabel: string = 'yes';
    @Input() public cancelButtonLabel: string = 'no';
    @Input() public displayCancelButton: boolean = true;
    @Input() public noCloseOnConfirm?: boolean = false;

    @Output() public confirm = new EventEmitter<void>();
    @Output() public cancel = new EventEmitter<void>();
    @Output() public xClose = new EventEmitter<void>();

    public visible: ModelSignal<any> = model(false);
    public subjectContext: ModelSignal<string> = model('');
    public subject: ModelSignal<string> = model('');

    protected readonly Severity = Severity;

    protected onConfirm() {
        if (!this.noCloseOnConfirm) this.close();
        this.confirm.emit();
    }

    protected onRadCancel() {
        this.close();
        this.cancel.emit();
    }

    public open(subjectContext: string, subject?: string) {
        this.subjectContext.set(subjectContext);
        this.subject.set(subject ?? '');
        this.visible.set(true);
    }

    public close() {
        this.visible.set(false);
    }

    public onXClose() {
        this.xClose.emit();
    }
}
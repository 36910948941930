import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {StateService} from 'src/app/services/core/state.service';

export const stateInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
    const stateService = inject(StateService);
    stateService.increaseApiCount(req.urlWithParams);

    return next(req).pipe(
        finalize(() => stateService.decreaseApiCount(req.url))
    );
};
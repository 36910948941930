import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Controller} from 'src/app/enums/backend/core/controller.enum';
import {StatisticControllerEndpoint} from 'src/app/enums/backend/core/endpoints/statistic-controller-endpoint.enum';
import {RadStatisticDto} from 'src/app/interfaces/backend/dto/rad-statistic-dto.interface';
import {EndpointsService} from 'src/app/services/core/endpoints.service';

@Injectable({providedIn: 'root'})
export class StatisticControllerService {
    public constructor(
        private endpointsService: EndpointsService
    ) {
    }

    public getStatisticAssignedDescriptionOrdersForEachDoctor(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(Controller.STATISTIC, StatisticControllerEndpoint.GET_STATISTIC_ASSIGNED_DESCRIPTION_ORDERS_FOR_EACH_DOCTOR);
    }

    public getStatisticCountOrdersCompletedForEachDoctor(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(Controller.STATISTIC, StatisticControllerEndpoint.GET_STATISTIC_COUNT_ORDERS_COMPLETED_FOR_EACH_DOCTOR);
    }

    public getStatisticCountOrdersForEachUnits(): Observable<RadStatisticDto[]> {
        return this.endpointsService.send(Controller.STATISTIC, StatisticControllerEndpoint.GET_STATISTIC_COUNT_ORDERS_FOR_EACH_UNITS);
    }
}
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {SidebarItem} from 'src/app/interfaces/core/sidebar-item.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-sidebar-item',
    standalone: true,
    imports: [
        TranslateModule,
        ...RAD_MODULES
    ],
    templateUrl: './sidebar-item.component.html'
})
export class SidebarItemComponent {
    @Input() public item?: SidebarItem;
    @Input() public icon?: GoogleIcon;
    @Input() public label: string = '';
    @Input() public severity?: Severity;
    @Input() public text: boolean = true;
    @Input() public firstLevelHorizontalLayout?: boolean;
    @Output() public itemClick: EventEmitter<void> = new EventEmitter<void>();

    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Severity = Severity;
    protected readonly Size = Size;

    protected get finalLabel() {
        return this.label || this.item?.label || ('enums.SiteRoute.' + this.item?.key);
    }

    protected get finalIcon() {
        return this.item?.icon ?? this.icon;
    }

    protected get finalText() {
        return this.item ?
            (this.firstLevelHorizontalLayout ? !this.item?.expanded : (!this.item?.active || this.item?.expanded)) :
            this.text;
    }

    protected onClick() {
        this.itemClick.emit();
    }
}
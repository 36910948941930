import {Injectable} from '@angular/core';
import {BehaviorSubject, debounce, debounceTime, Observable, Subscription, tap, timer, withLatestFrom} from 'rxjs';
import {State} from 'src/app/enums/core/state.enum';
import {
    DELAYED_STATE_TIME,
    LOADER_IGNORED_URLS,
    UPDATE_STATE_DEBOUNCE_TIME
} from 'src/app/helpers/core/site-config.helper';
import {environment} from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class StateService {
    public globalState$: Observable<State>;
    public globalStateDelayed$: Observable<State>;
    public apiCount$: Observable<number>;
    public increase$: Observable<null>;
    public decrease$: Observable<null>;

    private _globalState$ = new BehaviorSubject<State>(State.INITIATE);
    private _apiCount$ = new BehaviorSubject<number>(0);
    private _increase$ = new BehaviorSubject<null>(null);
    private _decrease$ = new BehaviorSubject<null>(null);

    private subscriptions: Subscription = new Subscription();

    public constructor() {
        this.globalState$ = this._globalState$.asObservable();
        this.apiCount$ = this._apiCount$.asObservable();
        this.increase$ = this._increase$.asObservable();
        this.decrease$ = this._decrease$.asObservable();
        this.globalStateDelayed$ = this.getGlobalStateDelayed();
        this.subscriptions.add(this.watchForIncrease());
        this.subscriptions.add(this.watchForDecrease());
        this.subscriptions.add(this.watchForApis());
    }

    private getGlobalState() {
        return this._globalState$.getValue();
    }

    private setGlobalState(value: State) {
        this._globalState$.next(value);
    }

    private getApiCount() {
        return this._apiCount$.getValue();
    }

    private setApiCount(value: number) {
        this._apiCount$.next(value);
    }

    private setIncrease() {
        this._increase$.next(null);
    }

    private setDecrease() {
        this._decrease$.next(null);
    }

    private getGlobalStateDelayed() {
        return this.globalState$.pipe(
            debounce(value => {
                return timer(value === State.INITIATE ? 0 : DELAYED_STATE_TIME)
            })
        );
    }

    private watchForIncrease() {
        return this.increase$.pipe(
            withLatestFrom(this.apiCount$),
            tap(([increase, apiCount]) => {
                this.setApiCount(apiCount + 1);
            })
        ).subscribe();
    }

    private watchForDecrease() {
        return this.decrease$.pipe(
            withLatestFrom(this.apiCount$),
            tap(([decrease, apiCount]) => {
                this.setApiCount(apiCount - 1);
            })
        ).subscribe();
    }

    private watchForApis() {
        return this.apiCount$.pipe(
            withLatestFrom(this.globalState$),
            debounceTime(UPDATE_STATE_DEBOUNCE_TIME),
            tap(([apiCount, globalState]) => {
                const newState = apiCount ? State.LOADING : State.INITIATE;
                if (newState === globalState) return;

                this.setGlobalState(newState);
            })
        ).subscribe();
    }

    private shouldRequestBeIgnored(reqUrl: string) {
        return LOADER_IGNORED_URLS.some((entry) => {
            const reqUrlNoQueryParams = reqUrl.split('?')[0].replace(environment.ED24_SERVER, '');
            const reqUrlParts = reqUrlNoQueryParams.split('/');
            const urlParts = entry.split('/');
            if (urlParts.length !== reqUrlParts.length) return false;

            const results = urlParts.map((innerEntry, index) => {
                if (innerEntry === ':param') {
                    return true;
                }

                return innerEntry === reqUrlParts[index];
            });

            return results.every(innerEntry => innerEntry);
        });
    }

    public increaseApiCount(reqUrl: string) {
        if (this.shouldRequestBeIgnored(reqUrl)) return;

        this.setIncrease();
    }

    public decreaseApiCount(reqUrl: string) {
        if (this.shouldRequestBeIgnored(reqUrl)) return;

        this.setDecrease();
    }
}

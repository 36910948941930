import {Injectable} from '@angular/core';
import {AmChartData} from 'src/app/interfaces/core/am-chart/am-chart-data.interface';
import {
    mapRadStatisticDtoListToAmChartDataList,
    mapRadStatisticDtoListToAmChartDataListEachUnit
} from 'src/app/mappers/charts.mapper';
import {StatisticControllerService} from 'src/app/services/backend/statistic-controller.service';

@Injectable({providedIn: 'root'})
export class StatisticFacade {
    public assignedTasksOrdersForEachDoctor: AmChartData[] = [];
    public countOrdersCompletedForEachDoctor: AmChartData[] = [];
    public countOrdersForEachUnits: AmChartData[] = [];

    constructor(
        private statisticControllerService: StatisticControllerService
    ) {
    }

    public reset() {
        this.assignedTasksOrdersForEachDoctor = [];
        this.countOrdersCompletedForEachDoctor = [];
        this.countOrdersForEachUnits = [];
    }

    public getStatisticAssignedDescriptionOrdersForEachDoctor() {
        this.statisticControllerService.getStatisticAssignedDescriptionOrdersForEachDoctor().subscribe((value) => {
            this.assignedTasksOrdersForEachDoctor = mapRadStatisticDtoListToAmChartDataList(value)
        });
    }

    public getStatisticCountOrdersCompletedForEachDoctor() {
        this.statisticControllerService.getStatisticCountOrdersCompletedForEachDoctor().subscribe((value) => {
            this.countOrdersCompletedForEachDoctor = mapRadStatisticDtoListToAmChartDataList(value)
        });
    }

    public getStatisticCountOrdersForEachUnits() {
        this.statisticControllerService.getStatisticCountOrdersForEachUnits().subscribe((value) => {
            this.countOrdersForEachUnits = mapRadStatisticDtoListToAmChartDataListEachUnit(value)
        });
    }
}

import {Route} from '@angular/router';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SITE_COMPONENT_LOADER} from 'src/app/helpers/core/site-component-loader.helper';
import {HOME_PAGE, SITE_ROUTING} from 'src/app/helpers/core/site-config.helper';
import {findKeys, findSiteNodeConditions, isComponentSecured} from 'src/app/helpers/core/site-tree-logic.helper';
import {SITE_TREE} from 'src/app/helpers/core/site-tree.helper';
import {SiteBranch} from 'src/app/interfaces/core/site-branch.interface';
import {SiteNode} from 'src/app/interfaces/core/site-node.interface';
import {coreRouteActivator} from 'src/app/route-activators/core.route-activator';
import {findDuplicates} from 'src/app/utilities/misc.util';
import {findNestedObjectByKeyAndValue} from 'src/app/utilities/object.util';

export const prepareSiteRoutes = () => {
    const paths = Object.keys(SITE_ROUTING.SITE_PATHS_COMPONENTS);
    const routes = paths.map(rawPath => {
        const rawPathElements = rawPath.split('/');
        const componentName = SITE_ROUTING.SITE_PATHS_COMPONENTS[rawPath];
        const siteNodeId = SITE_ROUTING.SITE_PATHS_NODE_IDS[rawPath];
        const siteNode = findNestedObjectByKeyAndValue(SITE_TREE, RouteDataType.SITE_NODE_ID, siteNodeId);
        const secured = isComponentSecured(rawPathElements, SITE_TREE);
        const guard = siteNode[RouteDataType.GUARD];
        const specialGuard = siteNode[RouteDataType.SPECIAL_GUARD];
        const siteNodeConditions = findSiteNodeConditions(rawPathElements);
        const hideBreadcrumbs = siteNode[RouteDataType.HIDE_BREADCRUMBS];
        const componentLoader = SITE_COMPONENT_LOADER[componentName];
        SITE_ROUTING.SITE_NODE_IDS_COMPONENT_NAMES[siteNodeId] = componentName;
        const route: Route = {
            path: rawPath,
            runGuardsAndResolvers: 'always',
            data: {
                [RouteDataType.RAW_PATH]: rawPath,
                [RouteDataType.RAW_PATH_ELEMENTS]: rawPathElements,
                [RouteDataType.COMPONENT_NAME]: componentName,
                [RouteDataType.SITE_NODE_ID]: siteNodeId,
                [RouteDataType.SECURED]: secured,
                [RouteDataType.GUARD]: guard,
                [RouteDataType.SPECIAL_GUARD]: specialGuard,
                [RouteDataType.SITE_NODE_CONDITIONS]: siteNodeConditions,
                [RouteDataType.HIDE_BREADCRUMBS]: hideBreadcrumbs
            },
            loadComponent: componentLoader,
            canActivate: [coreRouteActivator]
        };

        return route;
    });

    return routes;
};



const siteNodeIds: SiteNodeId[] = [];

const checkForSiteNodeIdsDuplicatesLogic = (obj: SiteBranch) => {
    Object.keys(obj).forEach((entry) => {
        if (entry === 'siteNodeId') {
            siteNodeIds.push((obj as SiteNode)[entry] as SiteNodeId);
        } else if (obj[entry]?.constructor === Object) {
            checkForSiteNodeIdsDuplicatesLogic(obj[entry] as SiteBranch);
        }
    })
}

const checkForSiteNodeIdsDuplicates = () => {
    checkForSiteNodeIdsDuplicatesLogic(SITE_TREE);
    const duplicatedSiteNodeIds = findDuplicates(siteNodeIds);
    if (duplicatedSiteNodeIds.length) console.error('SITE TREE contains duplicated SiteNodeIds', duplicatedSiteNodeIds);
}

checkForSiteNodeIdsDuplicates();

export const buildSiteRouting = () => {
    SITE_ROUTING.SITE_PATHS_COMPONENTS = findKeys(SITE_TREE, RouteDataType.COMPONENT_NAME, undefined, undefined, true);
    SITE_ROUTING.SITE_NODE_IDS_PATHS = findKeys(SITE_TREE, RouteDataType.SITE_NODE_ID);
    SITE_ROUTING.SITE_PATHS_NODE_IDS = findKeys(SITE_TREE, RouteDataType.SITE_NODE_ID, undefined, undefined, true);
    SITE_ROUTING.SITE_ROUTES = prepareSiteRoutes();
    SITE_ROUTING.HOME_NAVIGATION_PATH = SITE_ROUTING.SITE_NODE_IDS_PATHS?.[HOME_PAGE];
};
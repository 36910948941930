<div class="d-flex flex-sm-row flex-column justify-content-between gap-2">
    <div class="overflow-x-clip">
        @if (bottombarService.component) {
            <ng-template [ngComponentOutlet]="bottombarService.component"
                         [ngComponentOutletInputs]="bottombarService.componentInputs"></ng-template>
        }
    </div>

    <div class="d-flex gap-2">
        <ng-container *ngFor="let entry of bottombarService.bottombarElements">
            @if (entry.display !== false) {
                <app-additional-element [additionalElement]="entry"></app-additional-element>
            }
        </ng-container>
    </div>
</div>
import {Injectable} from '@angular/core';
import {HelpFormModel} from 'src/app/models/forms/help-form.model';

@Injectable({providedIn: 'root'})
export class HelpFormService {
    public formModel: HelpFormModel | null = null;

    public openHelpForm() {
        this.formModel = new HelpFormModel();
    }
}
import {AsyncPipe, DOCUMENT, NgClass} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MainContainerComponent} from 'src/app/components/main-container/main-container.component';
import {Theme} from 'src/app/enums/core/theme.enum';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {BreadcrumbsService} from 'src/app/services/core/breadcrumbs.service';
import {CoreService} from 'src/app/services/core/core.service';
import {DataCleanerService} from 'src/app/services/core/data-cleaner.service';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';
import {LanguageService} from 'src/app/services/core/language.service';
import {MobileMenuService} from 'src/app/services/core/mobile-menu.service';
import {NavbarService} from 'src/app/services/core/navbar.service';
import {SchedulerService} from 'src/app/services/core/scheduler.service';
import {StateService} from 'src/app/services/core/state.service';
import {ThemeService} from 'src/app/services/core/theme.service';

const RAD_STANDALONE_COMPONENTS = [
    MainContainerComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    protected currentTheme$!: Observable<Theme | null>;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private coreService: CoreService,
        private themeService: ThemeService,
        private navbarService: NavbarService,
        private mobileMenuService: MobileMenuService,
        private breadcrumbsService: BreadcrumbsService,
        private languageService: LanguageService,
        private schedulerService: SchedulerService,
        private stateService: StateService,
        private profileFacade: ProfileFacade,
        private dataCleanerService: DataCleanerService,
        private keyboardShortcutsService: KeyboardShortcutsService
    ) {
    }

    public ngOnInit() {
        this.currentTheme$ = this.themeService.theme$;
        const node = this.document.createAttribute('data-szafirsdk-ready');
        node.value = 'yes';
        this.document.body.attributes.setNamedItem(node);
    }
}
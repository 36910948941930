import {computed, Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Role} from 'src/app/enums/backend/role.enum';
import {LoginState} from 'src/app/enums/core/login-state.enum';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeConditionType} from 'src/app/enums/core/site-node-condition-type.enum';
import {SiteNodeType} from 'src/app/enums/core/site-node-type.enum';
import {BadgesFacade} from 'src/app/facades/badges.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {SITE_ROUTING} from 'src/app/helpers/core/site-config.helper';
import {isAccessAllowedBySiteNodeConditions, verifyNavbarSubItems} from 'src/app/helpers/core/site-tree-logic.helper';
import {SITE_TREE} from 'src/app/helpers/core/site-tree.helper';
import {MobileMenuItem} from 'src/app/interfaces/core/mobile-menu-item.interface';
import {SiteBranch} from 'src/app/interfaces/core/site-branch.interface';
import {SiteNode} from 'src/app/interfaces/core/site-node.interface';
import {CoreService} from 'src/app/services/core/core.service';
import {clone, findNestedObjectByKey} from 'src/app/utilities/object.util';

@Injectable({
    providedIn: 'root'
})
export class MobileMenuService {
    public mobileMenu: MobileMenuItem[] = [];

    private navigationEnd$: Observable<boolean>;
    private cacheLoginState?: LoginState;
    private cacheRole?: Role | null;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private coreService: CoreService,
        private profileFacade: ProfileFacade,
        private badgesService: BadgesFacade
    ) {
        this.navigationEnd$ = this.coreService.navigationEnd$;
        this.subscriptions.add(this.watchForRouteData());
    }

    private watchForRouteData() {
        return this.navigationEnd$.subscribe(() => {
            const routeData = this.coreService.routeData;
            if (!routeData) {
                this.mobileMenu = [];
                return;
            }

            const loginState = Boolean(this.profileFacade.userProfileSignal()) ?
                LoginState.LOGGED_IN :
                LoginState.NOT_LOGGED_IN;
            const role = this.profileFacade.userProfileSignal()?.usType ?? null;
            if (
                this.cacheLoginState === loginState &&
                this.cacheRole === role &&
                false
            ) {
                this.updateItems();
                return;
            }

            this.cacheLoginState = loginState;
            this.cacheRole = role;
            const mobileMenu = this.prepareMobileMenu(SITE_TREE, routeData.path, loginState, role);
            this.mobileMenu = clone(mobileMenu ?? []);
        });
    }

    private prepareMobileMenu(obj: SiteBranch | SiteNode, path: string, loginState: LoginState, role: Role | null) {
        const keys = Object.keys(obj);
        const mobileMenuItems: MobileMenuItem[] = keys.map(key => {
            const siteBranchOrNode = ((obj as SiteBranch)[key] as SiteNode);
            if (siteBranchOrNode && siteBranchOrNode.constructor !== Object) return;
            if (!siteBranchOrNode.siteNodeType || ![
                SiteNodeType.NAVBAR,
                SiteNodeType.SIDEBAR
            ].includes(siteBranchOrNode.siteNodeType)) {
                return;
            }
            const siteNodeConditions = siteBranchOrNode.siteNodeConditions;
            if (!isAccessAllowedBySiteNodeConditions(
                {
                    [SiteNodeConditionType.LOGIN_STATE]: loginState,
                    [SiteNodeConditionType.ROLE]: role
                },
                siteNodeConditions
            )) {
                return;
            }

            const items = this.prepareMobileMenu(siteBranchOrNode, path, loginState, role);

            const componentName = items ?
                undefined :
                findNestedObjectByKey(siteBranchOrNode, RouteDataType.COMPONENT_NAME)?.componentName;

            const siteNodeId = items ?
                undefined :
                findNestedObjectByKey(siteBranchOrNode, RouteDataType.SITE_NODE_ID)?.siteNodeId;

            const anyAllowedComponent = verifyNavbarSubItems(loginState, role, siteBranchOrNode);
            if (!anyAllowedComponent) return;

            const routerLink = siteNodeId ? SITE_ROUTING.SITE_NODE_IDS_PATHS[siteNodeId] : undefined;
            const active = Boolean(items?.filter(entry => entry.routerLink && path === entry.routerLink || entry.active).length || routerLink && path === routerLink);
            const expanded = Boolean(items) && active;
            const label = siteBranchOrNode.label;
            const icon = siteBranchOrNode.icon;
            const badge = siteBranchOrNode.badge;
            const badgeValue = computed(() => badge ? this.badgesService.badgesSignal()[badge] : null);

            const result: MobileMenuItem = {
                componentName,
                key,
                label,
                badge,
                badgeValueSignal: badgeValue,
                icon,
                active,
                routerLink,
                items,
                expanded
            };
            return result;
        }).filter(Boolean) as MobileMenuItem[];

        return mobileMenuItems.length ? mobileMenuItems : undefined;
    }

    public updateItems() {
        this.updateItemsLogic(this.coreService.pathHistorySignal()[0].path, this.mobileMenu);
    }

    public updateItemsLogic(path: string, mobileMenu?: MobileMenuItem[]) {
        mobileMenu?.forEach((entry, index) => {
            mobileMenu[index].active = Boolean(mobileMenu[index].items?.filter(innerEntry => innerEntry.routerLink && path === innerEntry.routerLink || innerEntry.active).length || mobileMenu[index].routerLink && path === mobileMenu[index].routerLink);
            if (!mobileMenu[index].expanded) mobileMenu[index].expanded = Boolean(mobileMenu[index].items) && mobileMenu[index].active;
            this.updateItemsLogic(path, mobileMenu[index].items);
        });
    }

    private resetItems(mobileMenu?: MobileMenuItem[]) {
        mobileMenu?.forEach((entry, index) => {
            mobileMenu[index].active = false;
            mobileMenu[index].expanded = false;
            this.resetItems(mobileMenu[index].items);
        });
    }
}
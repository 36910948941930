<div class="d-flex flex-column gap-2">
    <div class="d-flex flex-row gap-1">
        @for (entry of tabs; track entry) {
            <p-button styleClass="p-button-icon-only w-100"
                      [size]="Size.SMALL"
                      [text]="activeTab !== entry"
                      (onClick)="selectTab(entry)">
                <app-google-icon [icon]="entry"></app-google-icon>
            </p-button>
        }
    </div>

    @if (activeTab === GoogleIcon.SEARCH) {
        <app-form [formModel]="formModel"
                  [displayCancelButton]="false"
                  [displayResetButton]="false"
                  [displaySaveButton]="false"></app-form>
    }

    @if (activeTab === GoogleIcon.SIDE_NAVIGATION) {
        <ng-content></ng-content>
    }
</div>
import {EnvironmentInjector, Injectable} from '@angular/core';
import {Sort} from 'src/app/enums/core/sort.enum';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {
    RadiologyNotificationControllerService
} from 'src/app/services/backend/radiology-notification-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class NotificationsFacade {
    public displayNotificationsSidebar: boolean = false;
    public displayNotificationsDialog: boolean = false;
    public unreadNotificationsCount: number = 0;

    public dataPage: Page<NotificationDto> | null = null;
    public dataPagePeek: Page<NotificationDto> | null = null;

    constructor(
        private radiologyNotificationControllerService: RadiologyNotificationControllerService,
        private injector: EnvironmentInjector
    ) {
    }

    public loadData(pageable: Pageable = {}) {
        this.radiologyNotificationControllerService.getWebNotifications({...pageable, sort: [`notificationDate,${Sort.DESC}`]})
            .subscribe(data => this.dataPage = data);
    }

    public loadDataPeek() {
        this.radiologyNotificationControllerService.getWebNotifications({size: 10, page: 0, sort: [`notificationDate,${Sort.DESC}`]})
            .subscribe(data => this.dataPagePeek = data);
    }

    public markNotificationAsRead(notificationId: number, callback?: () => void) {
        this.radiologyNotificationControllerService.markNotificationAsRead(notificationId).subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public markAllNotificationsAsRead(callback?: () => void) {
        this.radiologyNotificationControllerService.markAllNotificationsAsRead().subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public loadCountOfUnreadWebNotifications() {
        this.radiologyNotificationControllerService.getCountOfUnreadWebNotifications()
            .subscribe(data => this.unreadNotificationsCount = data);
    }
}
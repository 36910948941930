import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Sort} from 'src/app/enums/core/sort.enum';
import {MenuItemExt} from 'src/app/interfaces/core/prime-ng/menu-item-ext.interface';
import {TranslationService} from 'src/app/services/core/translation.service';

@Injectable({providedIn: 'root'})
export class ContextMenuService {
    public contextMenuItems$: Observable<MenuItemExt[]> = this.translationService.translateMenuItems([]);
    public displayContextMenuTrigger$: Observable<null>;
    public mouseEvent?: MouseEvent;
    public target?: HTMLElement;
    public contextItem: any;

    private _displayContextMenuTrigger$: BehaviorSubject<null> = new BehaviorSubject<null>(null);

    constructor(
        private translationService: TranslationService
    ) {
        this.displayContextMenuTrigger$ = this._displayContextMenuTrigger$.asObservable();
    }

    public setDisplayContextMenuTrigger() {
        this._displayContextMenuTrigger$.next(null);
    }

    public openContextMenu(mouseEvent: MouseEvent, contextMenuItems: MenuItemExt[], contextItem?: any, sortType?: Sort) {
        this.mouseEvent = mouseEvent;
        this.target = mouseEvent.currentTarget as HTMLElement;
        this.contextItem = contextItem;
        setTimeout(() => {
            this.contextMenuItems$ = this.translationService.translateMenuItems(contextMenuItems, sortType);
            this.setDisplayContextMenuTrigger();
        });
    }
}
import {AsyncPipe} from '@angular/common';
import {Component, ViewChild} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {Menu, MenuModule} from 'primeng/menu';
import {TabViewChangeEvent, TabViewModule} from 'primeng/tabview';
import {AdditionalElementType} from 'src/app/enums/core/additional-element-type.enum';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {AdditionalElementsModule} from 'src/app/modules/additional-elements/additional-elements.module';
import {HelperTopbarService} from 'src/app/services/core/helper-topbar.service';

const RAD_MODULES = [
    AdditionalElementsModule
];

const PRIME_NG_MODULES = [
    TabViewModule,
    MenuModule
];

@Component({
    selector: 'app-helper-topbar',
    standalone: true,
    imports: [
        AsyncPipe,
        TranslateModule,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './helper-topbar.component.html'
})
export class HelperTopbarComponent {
    @ViewChild(Menu) menu?: Menu;

    protected readonly AdditionalElementType = AdditionalElementType;
    protected readonly Size = Size;
    protected readonly GoogleIcon = GoogleIcon;

    constructor(
        protected helperTopbarService: HelperTopbarService
    ) {
    }

    protected changeView($event: TabViewChangeEvent) {
        this.helperTopbarService.helperTopbarElements[$event.index].command?.();
    }
}
import {NgClass} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NavbarItemComponent} from 'src/app/components/utilities/navbar-item/navbar-item.component';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Language} from 'src/app/enums/core/language.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {UserDto} from 'src/app/interfaces/backend/dto/user-dto.interface';
import {NavbarItem} from 'src/app/interfaces/core/navbar-item.interface';
import {RouteData} from 'src/app/interfaces/core/route-data.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';

const RAD_STANDALONE_COMPONENTS = [
    NavbarItemComponent
];

const RAD_MODULES = [
    ButtonsModule
];

@Component({
    selector: 'app-navbar',
    standalone: true,
    imports: [
        NgClass,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES
    ],
    templateUrl: './navbar.component.html'
})
export class NavbarComponent {
    @Input({required: true}) public routeData!: RouteData;
    @Input() public userProfile?: UserDto | null;
    @Input() public items: NavbarItem[] = [];
    @Input() public notificationsCount?: number;
    @Input() public currentLanguage?: Language | null;
    @Input() public pathIndex: number = 0;
    @Input() public indent: boolean = false;

    @Output() public navigate: EventEmitter<string> = new EventEmitter<string>();
    @Output() public openHelpForm: EventEmitter<void> = new EventEmitter<void>();
    @Output() public openNotifications: EventEmitter<void> = new EventEmitter<void>();
    @Output() public changeLanguage: EventEmitter<void> = new EventEmitter<void>();
    @Output() public changeTheme: EventEmitter<void> = new EventEmitter<void>();
    @Output() public logout: EventEmitter<void> = new EventEmitter<void>();

    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Severity = Severity;
    protected readonly Language = Language;

    protected onItemClick(item: NavbarItem) {
        item.command ? item.command() : item.items ? this.expand(item) : this.onNavigate(item);
    }

    private expand(item: NavbarItem) {
        item.expanded = !item.expanded;
    }

    private onNavigate(item: NavbarItem) {
        const routerLink = item.routerLink;
        if (!routerLink) return;

        this.navigate.emit(routerLink);
    }

    protected onOpenNotifications() {
        this.openNotifications.emit();
    }

    protected onOpenHelpForm() {
        this.openHelpForm.emit();
    }

    protected onChangeLanguage() {
        this.changeLanguage.emit();
    }

    protected onChangeTheme() {
        this.changeTheme.emit();
    }

    protected onLogout() {
        this.logout.emit();
    }
}
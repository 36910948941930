@let helperTopbarMenuItems = (helperTopbarService.helperTopbarMenuItems$ | async) ?? [] ;
@let activeView = helperTopbarService.activeViewSignal() ;

<div class="d-flex gap-4 align-items-baseline">
    <div class="overflow-x-auto py-1">
        <p-tabView (onChange)="changeView($event)">
            @for (entry of helperTopbarService.helperTopbarElements; track entry.tooltip) {
                <p-tabPanel [header]="(entry.translationPrefix ?? '') + (entry.tooltip ?? '') | translate"
                            [selected]="activeView === entry.tooltip">
                    <ng-template pTemplate="header">
                        <app-additional-element [additionalElement]="entry"
                                                [type]="activeView === entry.tooltip ? AdditionalElementType.BUTTON_ROUNDED_ICON_ONLY : AdditionalElementType.BUTTON_OUTLINED_ROUNDED_ICON_ONLY"
                                                [noClick]="true"></app-additional-element>
                    </ng-template>
                </p-tabPanel>
            }
        </p-tabView>
    </div>

    @if (helperTopbarMenuItems.length) {
        <p-button styleClass="p-button-icon-only"
                  [size]="Size.SMALL"
                  [outlined]="true"
                  [rounded]="true"
                  (onClick)="menu?.toggle($event)">
            <app-google-icon [icon]="GoogleIcon.MORE_VERT"></app-google-icon>
        </p-button>
    }
</div>

<p-menu [model]="helperTopbarMenuItems"
        appendTo="body"
        [popup]="true"
        [autoZIndex]="true"></p-menu>
<div class="d-flex flex-column"
     [ngClass]="GAP_Y">
    <div class="d-flex flex-column"
         [ngClass]="GAP_BUTTONS">
        @if (!dialogMode && notificationsFacade.dataPagePeek?.totalElements) {
            <p-button styleClass="w-100"
                      [size]="Size.SMALL"
                      [label]="'allNotifications' | translate"
                      (onClick)="openNotificationsDialog()"></p-button>
        }

        @if ((dialogMode ? notificationsFacade.dataPage : notificationsFacade.dataPagePeek)?.totalElements) {
            <p-button styleClass="w-100"
                      [outlined]="true"
                      [size]="Size.SMALL"
                      [label]="'markAllAsRead' | translate"
                      (onClick)="markAllNotificationsAsRead()"></p-button>
        }
    </div>

    <div class="d-flex flex-column overflow-y-auto"
         [ngClass]="GAP_Y"
         [ngStyle]="{'max-height': dialogMode ? '600px' : null}">
        @for (entry of (dialogMode ? notificationsFacade.dataPage : notificationsFacade.dataPagePeek)?.content; track entry.id) {
            <app-notification [notification]="entry"
                              [dialogMode]="dialogMode"
                              (markAsRead)="onMarkAsRead($event)"
                              (goToSubject)="onGoToSubject($event)"></app-notification>
        }
    </div>

    @if (!(dialogMode ? notificationsFacade.dataPage : notificationsFacade.dataPagePeek)?.totalElements) {
        <div class="text-center">{{ 'noNotifications' | translate }}</div>
    }

    @if (dialogMode && notificationsFacade.dataPage?.totalElements) {
        <p-paginator [rows]="perPage"
                     [totalRecords]="notificationsFacade.dataPage?.totalElements ?? 0"
                     [currentPageReportTemplate]="TABLE_SUMMARY_MESSAGE | translate"
                     (onPageChange)="getNotifications($event)"></p-paginator>
    }
</div>
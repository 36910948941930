import {computed, Injectable} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Role} from 'src/app/enums/backend/role.enum';
import {LoginState} from 'src/app/enums/core/login-state.enum';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeConditionType} from 'src/app/enums/core/site-node-condition-type.enum';
import {SiteNodeType} from 'src/app/enums/core/site-node-type.enum';
import {BadgesFacade} from 'src/app/facades/badges.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {SITE_ROUTING} from 'src/app/helpers/core/site-config.helper';
import {
    getFirstAvailableSiteNodeId,
    isAccessAllowedBySiteNodeConditions,
    verifyNavbarSubItems
} from 'src/app/helpers/core/site-tree-logic.helper';
import {SITE_TREE} from 'src/app/helpers/core/site-tree.helper';
import {NavbarItem} from 'src/app/interfaces/core/navbar-item.interface';
import {SidebarItem} from 'src/app/interfaces/core/sidebar-item.interface';
import {SiteBranch} from 'src/app/interfaces/core/site-branch.interface';
import {SiteNode} from 'src/app/interfaces/core/site-node.interface';
import {CoreService} from 'src/app/services/core/core.service';
import {clone, findNestedObjectByKey} from 'src/app/utilities/object.util';

@Injectable({
    providedIn: 'root'
})
export class NavbarService {
    public navbar: NavbarItem[] = [];
    public sidebar: SidebarItem[] = [];

    public activeNavbarItem?: string;

    private navigationEnd$: Observable<boolean>;
    private cacheLoginState?: LoginState;
    private cacheRole?: Role | null;
    private cacheNavbarActiveItem?: string;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private coreService: CoreService,
        private profileFacade: ProfileFacade,
        private badgesService: BadgesFacade
    ) {
        this.navigationEnd$ = this.coreService.navigationEnd$;
        this.subscriptions.add(this.watchForRouteData());
    }

    private watchForRouteData() {
        return this.navigationEnd$.subscribe(() => {
            const routeData = this.coreService.routeData;
            if (!routeData) {
                this.navbar = [];
                this.sidebar = [];
                return;
            }

            const pathElements = routeData.pathElements;
            const rawPathElements = routeData.rawPathElements;

            const loginState = Boolean(this.profileFacade.userProfileSignal()) ?
                LoginState.LOGGED_IN :
                LoginState.NOT_LOGGED_IN;
            const role = this.profileFacade.userProfileSignal()?.usType ?? null;
            if (
                this.cacheLoginState === loginState &&
                this.cacheRole === role &&
                false
            ) {
                this.updateNavbarItems(pathElements, this.navbar);
            } else {
                const navbar = this.prepareNavbar(SITE_TREE, pathElements, loginState, role);
                this.navbar = clone(navbar ?? []);
            }

            if (
                this.cacheLoginState === loginState &&
                this.cacheRole === role &&
                this.cacheNavbarActiveItem === this.activeNavbarItem &&
                false
            ) {
                this.updateSidebarItems(rawPathElements, this.sidebar);
            } else {
                const sidebarObj = this.prepareSidebarObj(SITE_TREE, rawPathElements) ?? {};
                const sidebar = this.prepareSidebar(sidebarObj, rawPathElements, loginState, role);
                this.sidebar = clone(sidebar ?? []);
            }

            this.cacheLoginState = loginState;
            this.cacheRole = role;
            this.cacheNavbarActiveItem = this.activeNavbarItem;
        });
    }

    public prepareSidebarObj(obj: SiteBranch | SiteNode, rawPathElements: string[]): SiteNode | undefined {
        const key = rawPathElements[0];
        const siteBranchOrNode = ((obj as SiteBranch)[key] as SiteNode);

        if (siteBranchOrNode.siteNodeType === SiteNodeType.SIDEBAR) return obj as SiteNode;
        if (rawPathElements.length > 1) return this.prepareSidebarObj(siteBranchOrNode, rawPathElements.slice(1, rawPathElements.length));

        return;
    }

    public prepareNavbar(obj: SiteBranch | SiteNode, pathElements: string[], loginState: LoginState, role: Role | null, index: number = 0) {
        const keys = Object.keys(obj);
        const navbarItems: NavbarItem[] = keys.map(key => {
            const siteBranchOrNode = ((obj as SiteBranch)[key] as SiteNode);
            if (siteBranchOrNode.siteNodeType !== SiteNodeType.NAVBAR) return;

            const siteNodeConditions = siteBranchOrNode.siteNodeConditions;
            if (!isAccessAllowedBySiteNodeConditions(
                {
                    [SiteNodeConditionType.LOGIN_STATE]: loginState,
                    [SiteNodeConditionType.ROLE]: role
                },
                siteNodeConditions)
            ) {
                return;
            }

            const anyAllowedComponent = verifyNavbarSubItems(loginState, role, siteBranchOrNode);
            if (!anyAllowedComponent) return;

            const items = this.prepareNavbar(siteBranchOrNode, pathElements, loginState, role, index + 1);
            const siteNodeId = items ?
                undefined :
                getFirstAvailableSiteNodeId(loginState, role, siteBranchOrNode);

            if (!items && !siteNodeId) return;

            const componentName = items ?
                undefined :
                findNestedObjectByKey(siteBranchOrNode, RouteDataType.SITE_NODE_ID)?.componentName;

            const routerLink = siteNodeId ? SITE_ROUTING.SITE_NODE_IDS_PATHS[siteNodeId] : undefined;
            const active = Boolean(items?.filter(entry => entry.key === pathElements[index] || entry.active).length) || key === pathElements[index];
            if (active) this.activeNavbarItem = key;
            const expanded = Boolean(items) && active;
            const label = siteBranchOrNode.label;
            const icon = siteBranchOrNode.icon;
            const result: NavbarItem = {
                componentName,
                key,
                label,
                icon,
                active,
                routerLink,
                items,
                expanded
            };

            return result;
        }).filter(Boolean) as NavbarItem[];

        return navbarItems.length ? navbarItems : undefined;
    }

    private prepareSidebar(obj: SiteBranch | SiteNode, rawPathElements: string[], loginState: LoginState, role: Role | null) {
        const keys = Object.keys(obj);
        const sidebarItems: SidebarItem[] = keys.map(key => {
            const siteBranchOrNode = ((obj as SiteBranch)[key] as SiteNode);
            if (siteBranchOrNode && siteBranchOrNode.constructor !== Object) return;
            if (siteBranchOrNode.siteNodeType !== SiteNodeType.SIDEBAR) return this.prepareSidebar(siteBranchOrNode, rawPathElements, loginState, role);

            const siteNodeConditions = siteBranchOrNode.siteNodeConditions;
            if (!isAccessAllowedBySiteNodeConditions({
                [SiteNodeConditionType.LOGIN_STATE]: loginState,
                [SiteNodeConditionType.ROLE]: role
            }, siteNodeConditions)) {
                return;
            }

            const items = this.prepareSidebar(siteBranchOrNode, rawPathElements, loginState, role);
            const siteNodeId = items ?
                undefined :
                getFirstAvailableSiteNodeId(loginState, role, siteBranchOrNode) ;//findNestedObjectByKey(siteBranchOrNode, RouteDataType.SITE_NODE_ID)?.siteNodeId;

            if (!items && !siteNodeId) return;

            const componentName = items ?
                undefined :
                findNestedObjectByKey(siteBranchOrNode, RouteDataType.COMPONENT_NAME)?.componentName;

            const routerLink = siteNodeId ? SITE_ROUTING.SITE_NODE_IDS_PATHS[siteNodeId] : undefined;
            const itemRawPathElements = routerLink?.split('/');
            const active = Boolean(
                items?.filter(entry => this.compareRawPathElements(rawPathElements, entry.rawPathElements) || entry.active).length ||
                this.compareRawPathElements(rawPathElements, itemRawPathElements));

            const expanded = Boolean(items) && active;
            const label = siteBranchOrNode.label;
            const icon = siteBranchOrNode.icon;
            const badge = siteBranchOrNode.badge;
            const badgeValue = computed(() => badge ? this.badgesService.badgesSignal()[badge] : null);
            const result: SidebarItem = {
                componentName,
                key,
                label,
                badge,
                badgeValueSignal: badgeValue,
                icon,
                active,
                routerLink,
                rawPathElements: itemRawPathElements,
                items,
                expanded
            };

            return result;
        }).filter(Boolean) as SidebarItem[];

        return sidebarItems.length ? sidebarItems : undefined;
    }

    private updateNavbarItems(pathElements: string[], navbar?: NavbarItem[], index: number = 0) {
        navbar?.forEach((entry, i) => {
            navbar[i].active = Boolean(navbar[i].items?.filter(innerEntry => innerEntry.key === pathElements[index] || entry.active).length) || navbar[i].key === pathElements[index];
            navbar[i].expanded = Boolean(navbar[i].items) && navbar[i].active;
            if (navbar[i].active) this.activeNavbarItem = navbar[i].key;
            this.updateNavbarItems(pathElements, navbar[i].items, index + 1);
        });
    }

    private compareRawPathElements(routeRawPathElements: string[], itemRawPathElements?: string[]) {
        if (!itemRawPathElements) return false;
        if (itemRawPathElements.length > routeRawPathElements.length) return false;

        let resultsArray = itemRawPathElements.map((entry, index) => entry === routeRawPathElements[index]);
        return !resultsArray.includes(false);
    }

    private updateSidebarItems(rawPathElements: string[], sidebar?: SidebarItem[]) {
        sidebar?.forEach((entry, index) => {
            const itemRawPathElements = sidebar[index].routerLink?.split('/');
            sidebar[index].active = Boolean(
                sidebar[index].items?.filter(innerEntry => this.compareRawPathElements(rawPathElements, innerEntry.rawPathElements) || innerEntry.active).length ||
                this.compareRawPathElements(rawPathElements, itemRawPathElements));
            sidebar[index].expanded = Boolean(sidebar[index].items) && sidebar[index].active;
            this.updateSidebarItems(rawPathElements, sidebar[index].items);
        });
    }
}
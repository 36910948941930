import {Component, OnInit} from '@angular/core';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {SearchFormModel} from 'src/app/models/forms/search-form.model';
import {FormUtilsModule} from 'src/app/modules/form-utils/form-utils.module';

const RAD_MODULES = [
    FormUtilsModule
];

@Component({
    selector: 'app-sidebar-utils-wrapper',
    standalone: true,
    imports: [
        ...RAD_MODULES
    ],
    templateUrl: './sidebar-utils-wrapper.component.html'
})
export class SidebarUtilsWrapperComponent implements OnInit {
    protected activeTab: GoogleIcon = GoogleIcon.SIDE_NAVIGATION;
    protected tabs: GoogleIcon[] = [
        GoogleIcon.SEARCH,
        GoogleIcon.SIDE_NAVIGATION
    ];
    protected formModel!: SearchFormModel;

    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Size = Size;

    public ngOnInit() {
        this.formModel = new SearchFormModel();
    }

    protected selectTab(tab: GoogleIcon) {
        this.activeTab = tab;
    }
}
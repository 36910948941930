import {Injectable} from '@angular/core';
import {Observable, Subscription, timer} from 'rxjs';
import {BadgesFacade} from 'src/app/facades/badges.facade';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {BE_DATA_FETCH_INTERVAL_TIME} from 'src/app/helpers/core/site-config.helper';

@Injectable({
    providedIn: 'root'
})
export class SchedulerService {
    private subscriptions: Subscription = new Subscription();
    private watchersAdded: any = {
        refreshNotificationsPeek: false
    };
    private userLogged$: Observable<boolean>;

    constructor(
        private profileFacade: ProfileFacade,
        private notificationsFacade: NotificationsFacade,
        private badgesFacade: BadgesFacade
    ) {
        this.userLogged$ = this.profileFacade.userLogged$;
        this.initiateWatchers();
    }

    private initiateWatchers() {
        this.subscriptions.add(this.watchForUserLogged());
    }

    private watchForUserLogged() {
        return this.userLogged$.subscribe((value) => {
            if (value && !this.watchersAdded.refreshNotificationsPeek) {
                this.watchersAdded.refreshNotificationsPeek = true;
                this.subscriptions.add(this.generalScheduler());
            }
        });
    }

    private generalScheduler() {
        return timer(0, BE_DATA_FETCH_INTERVAL_TIME).subscribe(() => {
            if (!this.profileFacade.userProfileSignal()) return;

            this.notificationsFacade.loadDataPeek();
            this.notificationsFacade.loadCountOfUnreadWebNotifications();
            this.badgesFacade.loadRadiologyTaskCountPerStatus();
        });
    }
}
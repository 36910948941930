import {HttpClient, provideHttpClient, withInterceptors} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {provideRouter} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {errorInterceptor} from 'src/app/interceptors/error.interceptor';
import {stateInterceptor} from 'src/app/interceptors/state.interceptor';
import {routes} from 'src/app/routes/main.routes';

const PRIME_NG_PROVIDERS = [
    MessageService
];

const initializeAppFactory = (primeConfig: PrimeNGConfig) => () => {
    primeConfig.ripple = true;
};

export const appConfig: ApplicationConfig = {
    providers: [
        /* provideExperimentalZonelessChangeDetection(), */
        provideHttpClient(withInterceptors([
            stateInterceptor,
            errorInterceptor
        ])),
        provideRouter(routes),
        importProvidersFrom([
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useFactory: HttpLoaderFactory,
                        deps: [HttpClient]
                    }
                })
            ]
        ),
        provideAnimations(),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [PrimeNGConfig],
            multi: true
        },
        PRIME_NG_PROVIDERS
    ]
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

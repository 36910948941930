<p-button styleClass="w-100"
          [size]="Size.SMALL"
          [text]="finalText"
          [outlined]="!finalText"
          [label]="finalLabel | translate"
          (onClick)="onClick()">
    @if (finalIcon) {
        <app-google-icon [icon]="finalIcon"></app-google-icon>
    }

    @if (item?.items && !firstLevelHorizontalLayout) {
        <app-google-icon class="p-button-icon-right"
                         [icon]="item?.expanded ? GoogleIcon.ARROW_DROP_UP : GoogleIcon.ARROW_DROP_DOWN"></app-google-icon>
    }

    @if (item?.badge && item?.badgeValueSignal?.()) {
        <p-badge class="order-1"
                 [value]="item?.badgeValueSignal?.()"
                 [severity]="Severity.WARNING"></p-badge>
    }
</p-button>
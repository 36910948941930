import {NgClass, NgForOf, NgStyle} from '@angular/common';
import {Component, EnvironmentInjector, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {Observable, skip, Subscription, tap} from 'rxjs';
import {AutoSubscribe} from 'src/app/decorators/auto-subscribe.decorator';
import {AutoUnsubscribe} from 'src/app/decorators/auto-unsubscribe.decorator';
import {LetDirective} from 'src/app/directives/let.directive';
import {Color} from 'src/app/enums/core/color.enum';
import {Command} from 'src/app/enums/core/command.enum';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {KeyboardShortcutActivatedCommand} from 'src/app/interfaces/core/keyboard-shortcut-activated-command.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {BreadcrumbsService} from 'src/app/services/core/breadcrumbs.service';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';
import {displayInfo} from 'src/app/utilities/toast.util';

const RAD_MODULES = [
    ButtonsModule
];

const RAD_STANDALONE_DIRECTIVES = [
    LetDirective
];

@Component({
    selector: 'app-breadcrumbs',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        NgForOf,
        TranslateModule,
        ...RAD_MODULES,
        ...RAD_STANDALONE_DIRECTIVES
    ],
    templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit {
    protected readonly GoogleIcon = GoogleIcon;

    protected readonly Command = Command;
    protected readonly Color = Color;

    private keyboardShortcutActivatedCommand$!: Observable<KeyboardShortcutActivatedCommand | null>;
    @AutoUnsubscribe private subscriptions: Subscription = new Subscription();

    constructor(
        protected breadcrumbsService: BreadcrumbsService,
        private keyboardShortcutsService: KeyboardShortcutsService,
        private injector: EnvironmentInjector
    ) {
    }

    public ngOnInit() {
        this.keyboardShortcutActivatedCommand$ = this.keyboardShortcutsService.keyboardShortcutActivatedCommand$;
    }

    @AutoSubscribe
    private watchForKeyboardShortcutActivatedCommand = () => {
        return this.keyboardShortcutActivatedCommand$.pipe(
            skip(1),
            tap((keyboardShortcutActivatedCommand) => {
                const command = keyboardShortcutActivatedCommand?.command;
                if (command === Command.BACK && this.breadcrumbsService.previousSiteNodeId) {
                    this.back();
                    displayInfo(this.injector, command.toString(), {summary: 'keyboardShortcutActivated', translationPrefix: 'enums.Command.'});
                }
            })
        );
    };

    protected navigate(siteNodeId?: SiteNodeId) {
        if (!siteNodeId) return;

        this.breadcrumbsService.navigate(siteNodeId);
    }

    protected back() {
        this.breadcrumbsService.back();
    }
}
import {NgComponentOutlet, NgForOf} from '@angular/common';
import {Component} from '@angular/core';
import {MenuModule} from 'primeng/menu';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {AdditionalElementsModule} from 'src/app/modules/additional-elements/additional-elements.module';
import {BottombarService} from 'src/app/services/core/bottombar.service';

const RAD_MODULES = [
    AdditionalElementsModule
];

const PRIME_NG_MODULES = [
    MenuModule
];

@Component({
    selector: 'app-bottombar',
    standalone: true,
    imports: [
        NgForOf,
        NgComponentOutlet,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './bottombar.component.html'
})
export class BottombarComponent {
    protected readonly Severity = Severity;
    protected readonly Size = Size;
    protected readonly GoogleIcon = GoogleIcon;

    constructor(
        protected bottombarService: BottombarService
    ) {
    }
}
<div class="d-flex flex-md-row flex-column gap-2 justify-content-between">
    <div class="d-flex gap-2 flex-wrap align-items-center">
        <ng-container *ngFor="let entry of breadcrumbsService.breadcrumbs; index as index">
            @let siteNodeId = entry.siteNodeId;
            @let nonClickable = !entry.siteNodeId || index === breadcrumbsService.breadcrumbs.length - 1;

            @if (index) {
                <app-google-icon class="cursor-default"
                                 [icon]="GoogleIcon.CHEVRON_RIGHT"
                                 weight="700"
                                 [ngStyle]="{color: 'var(' + Color.PRIMARY_400 + ')'}"></app-google-icon>
            }

            <p-button [ngClass]="{'rad-breadcrumb-non-clickable': nonClickable}"
                      [label]="(entry.label ?? 'enums.SiteRoute.' + entry.key) | translate"
                      [link]="true"
                      (onClick)="navigate(nonClickable ? undefined : siteNodeId)"></p-button>
        </ng-container>
    </div>

    @if (breadcrumbsService.previousSiteNodeId) {
        <p-button [label]="Command.BACK | translate"
                  [text]="true"
                  (onClick)="back()"></p-button>
    }
</div>
import {NgClass, NgStyle} from '@angular/common';
import {Component, EnvironmentInjector, Input, OnInit} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {PaginatorModule} from 'primeng/paginator';
import {TableLazyLoadEvent} from 'primeng/table';
import {tap} from 'rxjs';
import {NotificationComponent} from 'src/app/components/sub-components/notification/notification.component';
import {NotificationContext} from 'src/app/enums/backend/notification-context.enum';
import {QueryParam} from 'src/app/enums/core/query-param.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {RadiologyTaskView} from 'src/app/enums/views/radiology-task-view.enum';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {RadiologyTasksFacade} from 'src/app/facades/radiology-tasks.facade';
import {GAP_BUTTONS, GAP_Y, TABLE_SUMMARY_MESSAGE} from 'src/app/helpers/core/site-config.helper';
import {RADIOLOGY_TASK_ON_ACTIONS_VIEW} from 'src/app/helpers/core/site-tree.helper';
import {PRIORITY_SEVERITY_MAP, STATUS_SITE_NODE_ACTION_MAP} from 'src/app/helpers/radiology-tasks.helper';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {NavigationParams} from 'src/app/interfaces/core/navigation-params.interface';
import {NavigationQueryParams} from 'src/app/interfaces/core/navigation-query-params.interface';
import {TypedObject} from 'src/app/interfaces/core/typed-object.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {RoutingService} from 'src/app/services/core/routing.service';
import {includes} from 'src/app/utilities/misc.util';
import {getTableLoad} from 'src/app/utilities/table.util';
import {displayError} from 'src/app/utilities/toast.util';

const RAD_STANDALONE_COMPONENTS = [
    NotificationComponent
];

const RAD_MODULES = [
    ButtonsModule
];

const PRIME_NG_MODULES = [
    PaginatorModule
];

@Component({
    selector: 'app-notifications',
    standalone: true,
    imports: [
        NgStyle,
        NgClass,
        TranslateModule,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
    @Input() public dialogMode: boolean = false;

    protected perPage: number = 10;
    protected pageable: Pageable = {page: 0, size: this.perPage};

    protected readonly PRIORITY_SEVERITY_MAP = PRIORITY_SEVERITY_MAP;

    constructor(
        protected notificationsFacade: NotificationsFacade,
        private radiologyTasksFacade: RadiologyTasksFacade,
        private routingService: RoutingService,
        private injector: EnvironmentInjector
    ) {
    }

    protected readonly GAP_Y = GAP_Y;
    protected readonly GAP_BUTTONS = GAP_BUTTONS;
    protected readonly TABLE_SUMMARY_MESSAGE = TABLE_SUMMARY_MESSAGE;
    protected readonly Size = Size;

    public ngOnInit() {
        if (!this.dialogMode) return;

        this.notificationsFacade.loadData(this.pageable);
    }

    protected openNotificationsDialog() {
        this.notificationsFacade.displayNotificationsSidebar = false;
        this.notificationsFacade.displayNotificationsDialog = true;
    }

    protected getNotifications($event: TableLazyLoadEvent) {
        this.pageable = getTableLoad($event, this.perPage).pageable;
        this.notificationsFacade.loadData(this.pageable);
    }

    private refreshData() {
        this.dialogMode ?
            this.notificationsFacade.loadData(this.pageable) :
            this.notificationsFacade.loadDataPeek();
        this.notificationsFacade.loadCountOfUnreadWebNotifications();
    }

    protected onMarkAsRead($event: NotificationDto, fn?: () => void) {
        const id = $event.id;
        if (!id) {
            displayError(this.injector);
            return;
        }

        const callback = () => {
            this.refreshData();
            fn?.();
        };

        this.notificationsFacade.markNotificationAsRead(id, callback);
    }

    protected markAllNotificationsAsRead() {
        const callback = () => this.refreshData();
        this.notificationsFacade.markAllNotificationsAsRead(callback);
    }

    protected onGoToSubject($event: NotificationDto) {
        const fn = () => {
            const {radTaskId, eventId, context, newDateStart, newDateStop} = $event;

            if (includes([NotificationContext.RadiologyTask, NotificationContext.RadiologyTaskNote], context)) {
                if (!radTaskId) return;

                this.radiologyTasksFacade.getItem(radTaskId).pipe(
                    tap((data) => {
                        const status = data.status;
                        if (!status) return;

                        this.notificationsFacade.displayNotificationsSidebar = false;
                        this.notificationsFacade.displayNotificationsDialog = false;

                        const siteNodeId = (RADIOLOGY_TASK_ON_ACTIONS_VIEW as TypedObject<SiteNodeId>)[STATUS_SITE_NODE_ACTION_MAP[status]];
                        const params: NavigationParams = {[SiteRoute.P_RADIOLOGY_TASK_ID]: radTaskId};
                        const queryParams: NavigationQueryParams | undefined = context === NotificationContext.RadiologyTaskNote ? {[QueryParam.TAB]: RadiologyTaskView.NOTES} : undefined;
                        this.routingService.navigateBySiteNodeId(siteNodeId, {params, queryParams});
                    })
                ).subscribe();
            }

            if (context === NotificationContext.RadiologyUserCalendar) {
                if (!eventId) return;

                this.notificationsFacade.displayNotificationsSidebar = false;
                this.notificationsFacade.displayNotificationsDialog = false;
                const queryParams: NavigationQueryParams = {
                    [QueryParam.EVENT_ID]: eventId,
                    [QueryParam.NEW_DATE_START]: newDateStart,
                    [QueryParam.NEW_DATE_STOP]: newDateStop
                };

                this.routingService.navigateBySiteNodeId(SiteNodeId.ABS, {queryParams});
            }
        };

        $event.isRead ? fn() : this.onMarkAsRead($event, fn);
    }
}
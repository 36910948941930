import {AsyncPipe, NgClass} from '@angular/common';
import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ContextMenu, ContextMenuModule} from 'primeng/contextmenu';
import {Menu, MenuModule} from 'primeng/menu';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SidebarModule} from 'primeng/sidebar';
import {ToastModule} from 'primeng/toast';
import {async, Observable, skip, Subscription, tap} from 'rxjs';
import {HelpFormComponent} from 'src/app/components/sub-components/help-form/help-form.component';
import {NotificationsComponent} from 'src/app/components/sub-components/notifications/notifications.component';
import {
    KeyboardShortcutsComponent
} from 'src/app/components/sub-components/settings-personal/keyboard-shortcuts/keyboard-shortcuts.component';
import {
    ZusCertificateUploadComponent
} from 'src/app/components/sub-components/zus-certificate-upload/zus-certificate-upload.component';
import {BottombarComponent} from 'src/app/components/utilities/bottombar/bottombar.component';
import {BreadcrumbsComponent} from 'src/app/components/utilities/breadcrumbs/breadcrumbs.component';
import {
    ConfirmDeleteDialogComponent
} from 'src/app/components/utilities/confirm-delete-dialog/confirm-delete-dialog.component';
import {ConfirmDialogComponent} from 'src/app/components/utilities/confirm-dialog/confirm-dialog.component';
import {DialogComponent} from 'src/app/components/utilities/dialog/dialog.component';
import {HelperTopbarComponent} from 'src/app/components/utilities/helper-topbar/helper-topbar.component';
import {InfoDialogComponent} from 'src/app/components/utilities/info-dialog/info-dialog.component';
import {MobileMenuComponent} from 'src/app/components/utilities/mobile-menu/mobile-menu.component';
import {NavbarComponent} from 'src/app/components/utilities/navbar/navbar.component';
import {ProfileManagerComponent} from 'src/app/components/utilities/profile-manager/profile-manager.component';
import {
    SidebarUtilsWrapperComponent
} from 'src/app/components/utilities/sidebar-utils-wrapper/sidebar-utils-wrapper.component';
import {SidebarComponent} from 'src/app/components/utilities/sidebar/sidebar.component';
import {UiBlockerComponent} from 'src/app/components/utilities/ui-blocker/ui-blocker.component';
import {AutoSubscribe} from 'src/app/decorators/auto-subscribe.decorator';
import {AutoUnsubscribe} from 'src/app/decorators/auto-unsubscribe.decorator';
import {Role} from 'src/app/enums/backend/role.enum';
import {Command} from 'src/app/enums/core/command.enum';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {State} from 'src/app/enums/core/state.enum';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {MARGIN_SECTION_BOTTOM, PADDING_Y} from 'src/app/helpers/core/site-config.helper';
import {KeyboardShortcutActivatedCommand} from 'src/app/interfaces/core/keyboard-shortcut-activated-command.interface';
import {MenuItemExt} from 'src/app/interfaces/core/prime-ng/menu-item-ext.interface';
import {SearchFormModel} from 'src/app/models/forms/search-form.model';
import {FormUtilsModule} from 'src/app/modules/form-utils/form-utils.module';
import {BottombarService} from 'src/app/services/core/bottombar.service';
import {ConfirmDeleteDialogService} from 'src/app/services/core/confirm-delete-dialog.service';
import {ConfirmDialogService} from 'src/app/services/core/confirm-dialog.service';
import {ContextMenuService} from 'src/app/services/core/context-menu.service';
import {CoreService} from 'src/app/services/core/core.service';
import {DialogsService} from 'src/app/services/core/dialogs.service';
import {HelperTopbarService} from 'src/app/services/core/helper-topbar.service';
import {InfoDialogService} from 'src/app/services/core/info-dialog.service';
import {KeyboardShortcutsService} from 'src/app/services/core/keyboard-shortcuts.service';
import {LanguageService} from 'src/app/services/core/language.service';
import {MobileMenuService} from 'src/app/services/core/mobile-menu.service';
import {NavbarService} from 'src/app/services/core/navbar.service';
import {RoutingService} from 'src/app/services/core/routing.service';
import {StateService} from 'src/app/services/core/state.service';
import {ThemeService} from 'src/app/services/core/theme.service';
import {HelpFormService} from 'src/app/services/forms/help-form.service';
import {environment} from 'src/environments/environment';

const RAD_STANDALONE_COMPONENTS = [
    NavbarComponent,
    SidebarComponent,
    HelperTopbarComponent,
    BottombarComponent,
    MobileMenuComponent,
    SidebarUtilsWrapperComponent,
    UiBlockerComponent,
    ConfirmDeleteDialogComponent,
    ConfirmDialogComponent,
    InfoDialogComponent,
    HelpFormComponent,
    NotificationsComponent,
    DialogComponent,
    KeyboardShortcutsComponent,
    ProfileManagerComponent,
    ZusCertificateUploadComponent,
    BreadcrumbsComponent
];

const RAD_MODULES = [
    FormUtilsModule
];

const PRIME_NG_MODULES = [
    ToastModule,
    ProgressSpinnerModule,
    SidebarModule,
    ContextMenuModule,
    MenuModule
];

@Component({
    selector: 'app-main-container',
    standalone: true,
    imports: [
        AsyncPipe,
        NgClass,
        RouterOutlet,
        ...RAD_STANDALONE_COMPONENTS,
        ...RAD_MODULES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './main-container.component.html'
})
export class MainContainerComponent implements OnInit {
    @ViewChild(ContextMenu) protected contextMenu?: ContextMenu;
    @ViewChild(Menu) protected menu?: Menu;

    protected globalStateDelayed$!: Observable<State>;
    protected formModel!: SearchFormModel;
    protected mobileMenuVisible: boolean = false;
    protected navigationState$!: Observable<boolean>;
    protected contextMenuItems$!: Observable<MenuItemExt[]>;
    protected displayContextMenu$!: Observable<null>;
    protected displayKeyboardShortcutsHelp: boolean = false;

    protected readonly State = State;
    protected readonly PADDING_Y = PADDING_Y;
    protected readonly MARGIN_SECTION_BOTTOM = MARGIN_SECTION_BOTTOM;
    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Severity = Severity;
    protected readonly Role = Role;
    protected readonly Size = Size;
    protected readonly environment = environment;

    private keyboardShortcutActivatedCommand$!: Observable<KeyboardShortcutActivatedCommand | null>;
    @AutoUnsubscribe private subscriptions: Subscription = new Subscription();

    constructor(
        protected navbarService: NavbarService,
        protected mobileMenuService: MobileMenuService,
        protected helperTopbarService: HelperTopbarService,
        protected bottombarService: BottombarService,
        protected confirmDeleteDialogService: ConfirmDeleteDialogService,
        protected confirmDialogService: ConfirmDialogService,
        protected infoDialogService: InfoDialogService,
        protected contextMenuService: ContextMenuService,
        protected profileFacade: ProfileFacade,
        protected notificationsFacade: NotificationsFacade,
        protected coreService: CoreService,
        protected helpFormService: HelpFormService,
        protected languageService: LanguageService,
        protected dialogsService: DialogsService,
        private stateService: StateService,
        private routingService: RoutingService,
        private themeService: ThemeService,
        private keyboardShortcutsService: KeyboardShortcutsService
    ) {
    }

    public ngOnInit() {
        this.contextMenuItems$ = this.contextMenuService.contextMenuItems$;
        this.navigationState$ = this.coreService.navigationState$;
        this.globalStateDelayed$ = this.stateService.globalStateDelayed$;
        this.displayContextMenu$ = this.contextMenuService.displayContextMenuTrigger$;
        this.keyboardShortcutActivatedCommand$ = this.keyboardShortcutsService.keyboardShortcutActivatedCommand$;
        this.formModel = new SearchFormModel();
    }

    @HostListener('window:resize', ['$event'])
    private onResize($event: Event) {
        if (window.outerWidth >= 1024) this.mobileMenuVisible = false;
    }

    @AutoSubscribe
    private watchForDisplayContextMenu = () => {
        return this.displayContextMenu$.pipe(
            tap((value) => {
                if (!this.contextMenu) return;
                this.contextMenu.target = this.contextMenuService.target;
                this.contextMenu.show(this.contextMenuService.mouseEvent);
            })
        );
    };

    @AutoSubscribe
    private watchForKeyboardShortcutActivatedCommand = () => {
        return this.keyboardShortcutActivatedCommand$.pipe(
            skip(1),
            tap((keyboardShortcutActivatedCommand) => {
                const {command, value} = keyboardShortcutActivatedCommand ?? {};
                if (!command) return;

                if (command === Command.KEYBOARD_SHORTCUTS_HELP) this.displayKeyboardShortcutsHelp = !this.displayKeyboardShortcutsHelp;
                if (!value) return;

                if ([
                    Command.NAVIGATE_1,
                    Command.NAVIGATE_2,
                    Command.NAVIGATE_3,
                    Command.NAVIGATE_4,
                    Command.NAVIGATE_5,
                    Command.NAVIGATE_6,
                    Command.NAVIGATE_7,
                    Command.NAVIGATE_8,
                    Command.NAVIGATE_9,
                    Command.NAVIGATE_10,
                    Command.NAVIGATE_11,
                    Command.NAVIGATE_12
                ].includes(command)) {
                    this.routingService.navigateBySiteNodeId(value as SiteNodeId);
                }
            })
        );
    };

    protected closeMobileMenu($event?: boolean) {
        if ($event || !this.mobileMenuVisible) return;

        this.mobileMenuVisible = false;
        this.mobileMenuService.updateItems();
    }

    protected onNavigate($event: string) {
        this.routingService.navigateByUrl($event);
    }

    protected onOpenNotifications() {
        this.mobileMenuVisible = false;
        this.notificationsFacade.displayNotificationsSidebar = true;
    }

    protected onOpenHelpForm() {
        this.mobileMenuVisible = false;
        this.helpFormService.openHelpForm();
    }

    protected onChangeLanguage() {
        this.languageService.toggleLanguage();
    }

    protected onChangeTheme() {
        this.themeService.changeTheme();
    }

    protected onLogout() {
        this.profileFacade.logout();
    }

    protected reloadPage() {
        this.routingService.reload();
    }

    protected readonly async = async;
}
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {SITE_TREE} from 'src/app/helpers/core/site-tree.helper';
import {BreadcrumbItem} from 'src/app/interfaces/core/breadcrumb-item.interface';
import {mapBreadcrumbsLabels, mapToBreadcrumbItem} from 'src/app/mappers/breadcrumbs.mapper';
import {CoreService} from 'src/app/services/core/core.service';
import {RoutingService} from 'src/app/services/core/routing.service';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {
    public breadcrumbs: BreadcrumbItem[] = [];
    public previousSiteNodeId: SiteNodeId | null = null;

    private params: Params = {};
    private queryParams: Params = {};
    private navigationEnd$: Observable<boolean>;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private coreService: CoreService,
        private routingService: RoutingService
    ) {
        this.navigationEnd$ = this.coreService.navigationEnd$;
        this.subscriptions.add(this.watchForRouteData());
    }

    private watchForRouteData() {
        return this.navigationEnd$.subscribe(() => {
            const routeData = this.coreService.routeData;
            if (!routeData || routeData.data[RouteDataType.HIDE_BREADCRUMBS]) {
                this.breadcrumbs = [];
                this.previousSiteNodeId = null;
                return;
            }


            const {rawPathElements, routeSubjects, params, queryParams} = routeData;
            this.params = params;
            this.queryParams = queryParams;
            this.breadcrumbs = this.prepareBreadcrumbs(SITE_TREE, rawPathElements as SiteRoute[], [])
                                   .filter(entry => entry.key);
            this.breadcrumbs = mapBreadcrumbsLabels(this.breadcrumbs, routeSubjects);
            this.previousSiteNodeId = this.breadcrumbs.map(entry => entry.siteNodeId).filter(Boolean).at(-2) ?? null;
        });
    }

    private prepareBreadcrumbs = (obj: any, keys: SiteRoute[], result: BreadcrumbItem[], previousKey?: SiteRoute): BreadcrumbItem[] => {
        const found = mapToBreadcrumbItem(obj, previousKey);
        result = [...result, found];
        if (!keys.length) return result;
        return this.prepareBreadcrumbs(obj[keys[0]], keys.slice(1, keys.length), result, keys[0]);
    };

    public navigate(siteNodeId: SiteNodeId) {
        this.routingService.navigateBySiteNodeId(siteNodeId, {params: this.params, queryParams: this.queryParams});
    }

    public back() {
        if (!this.previousSiteNodeId) return;

        this.routingService.navigateBySiteNodeId(this.previousSiteNodeId, {params: this.params, queryParams: this.queryParams});
    }
}
import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {NgLetContext} from 'src/app/interfaces/core/ng-let-context.interface';

@Directive({
    selector: '[ngLet]',
    standalone: true
})
export class LetDirective {
    @Input()
    set ngLet(value: any) {
        this._context.ngLet = value;
    }

    private _context: NgLetContext = {ngLet: null};

    constructor(
        private _viewContainer: ViewContainerRef,
        private _templateRef: TemplateRef<NgLetContext>
    ) {
        this._viewContainer.createEmbeddedView(_templateRef, this._context);
    }
}
import {Role} from 'src/app/enums/backend/role.enum';
import {LoginState} from 'src/app/enums/core/login-state.enum';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeConditionType} from 'src/app/enums/core/site-node-condition-type.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {SITE_TREE} from 'src/app/helpers/core/site-tree.helper';
import {SiteBranch} from 'src/app/interfaces/core/site-branch.interface';
import {SiteNodeCondition} from 'src/app/interfaces/core/site-node-condition.interface';
import {SiteNode} from 'src/app/interfaces/core/site-node.interface';
import {StringObject} from 'src/app/interfaces/core/string-object.interface';
import {TypedObject} from 'src/app/interfaces/core/typed-object.interface';
import {getNestedValue, getObjectValuesAsStringArray} from 'src/app/utilities/object.util';

export const findKeys = (obj: any, desiredKey: string, path: string[] = [], results: StringObject = {}, reverse: boolean = false) => {
    const keys = Object.keys(obj);
    keys.forEach((key) => {
        let currentPath = path;
        if (key === desiredKey) {
            if (reverse) {
                results[currentPath.join('/')] = obj[key];
            } else {
                results[obj[key]] = currentPath.join('/');
            }
        }
        currentPath = currentPath.concat([key]);
        if (typeof obj[key] === 'object') {
            findKeys(obj[key], desiredKey, currentPath, results, reverse);
        }
    });
    return results;
};

export const isAccessAllowedBySiteNodeConditions = (values: TypedObject<any>, siteNodeConditions?: SiteNodeCondition[]) => {
    if (!siteNodeConditions) return true;

    const results = siteNodeConditions.map((entry) => isAccessAllowedBySiteNodeCondition(values, entry));
    return results.every(entry => entry);
};

export const isAccessAllowedBySiteNodeCondition = (values: TypedObject<any>, siteNodeCondition: SiteNodeCondition) => {
    const siteNodeConditionType = siteNodeCondition.siteNodeConditionType;
    if (!siteNodeConditionType) return true;

    const value = values[siteNodeConditionType];
    if (value === undefined) return false;

    return siteNodeCondition.values.includes(value);
};

export const isComponentSecured = (rawPathElements: string[], obj: SiteBranch): boolean => {
    if (rawPathElements.length === 0) return false;

    const siteNodeConditions = (obj[rawPathElements[0]] as SiteNode).siteNodeConditions;
    const siteNodeCondition = siteNodeConditions?.find(entry => entry.siteNodeConditionType === SiteNodeConditionType.LOGIN_STATE);
    if (!siteNodeCondition) return isComponentSecured(rawPathElements.slice(1, rawPathElements.length), (obj[rawPathElements[0]] as SiteBranch));

    return isAccessAllowedBySiteNodeCondition({[SiteNodeConditionType.LOGIN_STATE]: LoginState.LOGGED_IN}, siteNodeCondition);
};

export const findSiteNodeConditions = (rawPathElements: string[]) => {
    let siteNodeConditions: SiteNodeCondition[] = [];
    for (let i = 0; i < rawPathElements.length; i++) {
        const found = getNestedValue(SITE_TREE, [
            ...rawPathElements.slice(0, rawPathElements.length - i),
            RouteDataType.SITE_NODE_CONDITIONS
        ]);
        if (found) siteNodeConditions = siteNodeConditions.concat(found);
    }

    return siteNodeConditions.flatMap(entry => entry);
};

export const verifyNavbarSubItems = (loginState: LoginState, role: Role | null, obj: SiteBranch | SiteNode) => {
    if (obj.constructor !== Object) return;

    const siteNodeConditions = (obj as SiteNode).siteNodeConditions;
    const componentName = (obj as SiteNode).componentName;
    if (componentName && siteNodeConditions) {
        return isAccessAllowedBySiteNodeConditions({
            [SiteNodeConditionType.ROLE]: role,
            [SiteNodeConditionType.LOGIN_STATE]: loginState
        }, siteNodeConditions);
    }

    const results: (boolean | undefined)[] = Object.keys((obj as SiteNode)).flatMap((entry) => {
        if (!getObjectValuesAsStringArray(SiteRoute).includes(entry)) return;

        return verifyNavbarSubItems(loginState, role, (obj as SiteBranch)[entry] as SiteBranch);
    }).filter(entry => entry !== undefined);

    const finalResult = results.length ? results.some(entry => entry === true) : true;
    return finalResult;
};

export const getFirstAvailableSiteNodeId = (loginState: LoginState, role: Role | null, obj: SiteBranch | SiteNode): SiteNodeId | undefined => {
    if (obj.constructor !== Object) return;

    const {siteNodeConditions, siteNodeId, siteNodeType} = (obj as SiteNode);

    if (siteNodeId) {
        if (!siteNodeConditions) return siteNodeId;

        const isAccessAllowedBySiteNodeConditionsResult = isAccessAllowedBySiteNodeConditions({
            [SiteNodeConditionType.ROLE]: role,
            [SiteNodeConditionType.LOGIN_STATE]: loginState
        }, siteNodeConditions);

        return isAccessAllowedBySiteNodeConditionsResult ? siteNodeId : undefined;
    }

    const siteNodesIds = Object.keys((obj as SiteNode)).flatMap((entry) => {
        if (!getObjectValuesAsStringArray(SiteRoute).includes(entry)) return;

        return getFirstAvailableSiteNodeId(loginState, role, (obj as SiteBranch)[entry] as SiteBranch);
    }).filter(Boolean);

    return siteNodesIds[0];
};
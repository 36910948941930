import {Component, OnInit} from '@angular/core';
import {tap} from 'rxjs';
import {Sort} from 'src/app/enums/core/sort.enum';
import {HelpFacade} from 'src/app/facades/help.facade';
import {InputsOptions} from 'src/app/interfaces/core/form/inputs-options.interface';
import {HelpFormControl, HelpFormData} from 'src/app/models/forms/help-form.model';
import {FormUtilsModule} from 'src/app/modules/form-utils/form-utils.module';
import {CoreService} from 'src/app/services/core/core.service';
import {HelpFormService} from 'src/app/services/forms/help-form.service';
import {prepareInputOptionsFromStringArray} from 'src/app/utilities/form.util';

const RAD_MODULES = [
    FormUtilsModule
];

@Component({
    selector: 'app-help-form',
    standalone: true,
    imports: [
        ...RAD_MODULES
    ],
    templateUrl: './help-form.component.html'
})
export class HelpFormComponent implements OnInit {
    protected inputsOptions: InputsOptions = {};

    constructor(
        protected helpFormService: HelpFormService,
        private helpFacade: HelpFacade,
        private coreService: CoreService
    ) {
    }

    public ngOnInit() {
        this.prepareInputsOptions();
    }

    private prepareInputsOptions() {
        this.helpFacade.getHelpSubjects().pipe(
            tap((data) => this.inputsOptions = {[HelpFormControl.SUBJECT]: prepareInputOptionsFromStringArray(data, {sortType: Sort.ASC})})
        ).subscribe();
    }

    protected send($event: HelpFormData) {
        const messageContent = $event[HelpFormControl.MESSAGE_CONTENT];
        $event[HelpFormControl.MESSAGE_CONTENT] = `<p>${this.coreService.pathHistorySignal()[0].path}</p>${messageContent}`;
        const callback = () => this.helpFormService.formModel = null;
        this.helpFacade.send($event, callback);
    }
}
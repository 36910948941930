@let finalIcon = item?.icon ?? icon ;

<div class="position-relative">
    <p-button [styleClass]="['w-100', severity ? null : 'rad-text-color-shade-000'].join(' ')"
              [size]="Size.SMALL"
              [text]="finalText"
              [outlined]="!severity && !finalText"
              [label]="finalLabel | translate"
              [severity]="severity"
              (onClick)="onClick()">
        @if (item?.badge && item?.badgeValueSignal?.()) {
            <p-badge styleClass="ms-0"
                     class="p-button-icon-left"
                     [value]="item?.badgeValueSignal?.()"
                     [severity]="Severity.WARNING"></p-badge>
        }

        @if (item?.items) {
            <app-google-icon class="p-button-icon-left"
                             [icon]="item?.expanded ? GoogleIcon.ARROW_DROP_UP : GoogleIcon.ARROW_DROP_DOWN"></app-google-icon>
        }

        @if (finalIcon) {
            <app-google-icon class="p-button-icon-right"
                             [icon]="finalIcon"></app-google-icon>
        }

    </p-button>

    @if (notificationsCount) {
        <p-badge styleClass="p-badge-dot"
                 [severity]="Severity.DANGER"></p-badge>
    }
</div>